<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('qr_code_verify_stream')"
                        :is-filter="false"/>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('qr_code_verify_stream')"
                              :is-filter="false"/>
            </template>
            <b-row>
                <b-col sm="12" md="12" class="text-center">
                    <h3>{{eventTitle.toUpper()}}</h3>
                </b-col>
                <b-col sm="12" md="6" offset-md="3" class="mt-2">
                    <b-button-group class="w-100">
                        <b-button variant="primary" @click="openQrCodeBox">{{$t('qr_code_read')}}</b-button>
                        <b-button variant="outline-primary" @click="openTicketBox">{{$t('ticket_no_enter')}}</b-button>
                    </b-button-group>
                </b-col>
                <b-col sm="12" md="6" offset-md="3" v-if="qrCodeBox" class="mt-2">
                    <b-card v-show="!isLoading">
                        <StreamBarcodeReader
                            @decode="onDecode"
                            @loaded="onLoaded"
                        ></StreamBarcodeReader>
                    </b-card>
                    <b-card v-if="isLoading == true" class="d-flex justify-content-center align-items-center" style="height: 416px">
                        <b-spinner type="grow" label="Spinning" style="margin-top: 450%"></b-spinner>
                    </b-card>
                </b-col>
                <b-col sm="12" md="6" offset-md="3" class="mt-2" v-if="ticketBox">
                    <b-card style="height: 416px">
                        <ValidationObserver ref="ticketNumberForm">
                            <b-row>
                                <b-col sm="12">
                                    <ValidationProvider name="ticket_code" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group>
                                            <b-form-input v-model="formData.ticket_code"
                                                          class="text-uppercase"
                                                          :placeholder="$t('ticket_no')"
                                                          :state="errors[0] ? false : null"/>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col sm="12">
                                    <b-button variant="primary" block @click="checkTicket" :disabled="formLoading">
                                        {{ $t('ticket_check') }}
                                    </b-button>
                                </b-col>
                            </b-row>
                        </ValidationObserver>
                    </b-card>
                </b-col>
            </b-row>

        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout"
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import { StreamBarcodeReader } from "vue-barcode-reader"
import TicketEventApplicationService from "@/services/TicketEventApplicationService"
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import TicketEventService from "@/services/TicketEventService"

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        StreamBarcodeReader,
        ValidationObserver,
        ValidationProvider
    },
    metaInfo() {
        return {
            title: this.$t('qr_code_verify_stream')
        }
    },
    data() {
        return {
            qrCodeBox: false,
            isLoading: true,
            ticketBox: false,
            formData: {},
            formLoading: false,
            eventTitle: '...'
        }
    },
    methods: {
        openQrCodeBox(){
            this.qrCodeBox = !this.qrCodeBox
            this.isLoading = true
            this.ticketBox = false
        },

        onDecode(result) {
            this.openQrCodeBox()
            let formData = {
                ticket_event_id: this.$route.params.eventId,
                ticket_code: result
            }
            TicketEventApplicationService.checkQrString(formData).then(response => {
                let data = response.data.data
                this.$swal.fire({
                    title: this.$t('confirmation'),
                    html: '<table style="width:100%; text-align: left;">' +
                        '<tr>' +
                        '<td>Ad:</td>' +
                        '<td><b>'+data.name+'</b></td>' +
                        '</tr>'+
                        '<tr>' +
                        '<td>Soyad:</td>' +
                        '<td><b>'+data.surname+'</b></td>' +
                        '</tr>'+
                        '<tr>' +
                        '<td>Bilet No:</td>' +
                        '<td><b>'+data.ticket_code+'</b></td>' +
                        '</tr>'+
                        '<tr>' +
                        '<td></td>' +
                        '<td style="color: red"><p style="margin-top: 15px"><b></b></p></td>' +
                        '</tr>'+
                        '<table/>',
                    showCancelButton: true,
                    confirmButtonText: this.$t('confirm'),
                    cancelButtonText: this.$t('close'),
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.confirmTicket(formData, 'qr')
                    } else if (result.isDismissed) {
                      this.openQrCodeBox()
                    }
                })
            }).catch(e => {
                this.$swal.fire({
                    title: this.$t('warning'),
                    text: this.$t('api.'+e.data.message),
                    showCancelButton: false,
                    confirmButtonText: this.$t('ok'),
                    allowOutsideClick: false
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.openQrCodeBox()
                  }
                })
            })
        },

        onLoaded() {
            this.isLoading = false
        },

        confirmTicket(ticketObject, mode) {
            if(mode == 'qr'){
                this.openQrCodeBox()
            } else {
                this.formData = {}
                this.$refs.ticketNumberForm.reset()
            }

            TicketEventApplicationService.confirmTicket(ticketObject).then(response => {
                this.$toast.success(this.$t('api.'+response.data.message))
            }).catch(e => {
                this.$swal.fire({
                    title: this.$t('warning'),
                    text: this.$t('api.'+e.data.message),
                    showCancelButton: false,
                    confirmButtonText: this.$t('ok'),
                })
            })
        },

        openTicketBox(){
            this.ticketBox = !this.ticketBox
            this.qrCodeBox = false
        },

        async checkTicket(){
            const isValid = await this.$refs.ticketNumberForm.validate();
            if (isValid) {
                this.formLoading = true
                let formData = {
                    ticket_event_id: this.$route.params.eventId,
                    ticket_code: this.formData.ticket_code
                }
                TicketEventApplicationService.checkQrString(formData).then(response => {
                    let data = response.data.data
                    this.$swal.fire({
                        title: this.$t('confirmation'),
                        html: '<table style="width:100%; text-align: left;">' +
                            '<tr>' +
                            '<td>Ad:</td>' +
                            '<td><b>'+data.name+'</b></td>' +
                            '</tr>'+
                            '<tr>' +
                            '<td>Soyad:</td>' +
                            '<td><b>'+data.surname+'</b></td>' +
                            '</tr>'+
                            '<tr>' +
                            '<td>Bilet No:</td>' +
                            '<td><b>'+data.ticket_code+'</b></td>' +
                            '</tr>'+
                            '<tr>' +
                            '<td></td>' +
                            '<td style="color: red"><p style="margin-top: 15px"><b></b></p></td>' +
                            '</tr>'+
                            '<table/>',
                        showCancelButton: true,
                        confirmButtonText: this.$t('confirm'),
                        cancelButtonText: this.$t('close'),
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.confirmTicket(formData, 'ticket')
                        } else if (result.isDismissed) {
                            this.formData = {}
                            this.$refs.ticketNumberForm.reset()
                        }
                    })
                }).catch(e => {
                    this.$swal.fire({
                        title: this.$t('warning'),
                        text: this.$t('api.'+e.data.message),
                        showCancelButton: false,
                        confirmButtonText: this.$t('ok'),
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.formData = {}
                            this.$refs.ticketNumberForm.reset()
                        }
                    })
                })
            }
        },

        getEventTitle(){
            TicketEventService.get(this.$route.params.eventId).then(response => {
                this.eventTitle = this.$i18n.locale == 'en' ? response.data.data.name_en : response.data.data.name
            }).catch(e => {
                this.showErrors(e)
            })
        }
    },

    created(){
        this.getEventTitle()
    }
}
</script>

